/*--------------------
  FOOTER
--------------------*/

.footer {
  padding: 3rem 0 7rem;
  color: #ffffff;
  background: url("../img/footer_bg-xs.webp") center top / cover no-repeat;

  @media screen and (min-width: $sm-min) {
    background-image: url("../img/footer_bg-sm.webp");
  }

  @media screen and (min-width: $md-min) {
    background-image: url("../img/footer_bg-md.webp");
  }

  @media screen and (min-width: $lg-min) {
    padding: 7rem 0 1rem;
    background-image: url("../img/footer_bg-lg.webp");
  }

  @media screen and (min-width: $xl-min) {
    background-image: url("../img/footer_bg-xl.webp");
  }

  a {
    color: inherit;
  }

  .container {
    max-width: 98rem;
  }

  .logo {
    max-width: 32.5rem;
    margin: 0 auto 4.5rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 7rem;
    }
  }

  &__nav {
    margin-bottom: 2rem;
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid #E4E4E4;

    @media screen and (min-width: $sm-min) {
      padding-bottom: 0.5rem;
    }

    @media screen and (min-width: $lg-min) {
      margin-bottom: 5rem;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;

      @media screen and (min-width: $lg-min) {
        max-width: 75rem;
        margin: 0 auto;
      }
    }

    li {
      @media screen and (max-width: $xs-max) {
        width: 49%;
      }

      @media screen and (min-width: $sm-min) {
        margin: 0 0.5rem 0.5rem;
      }

      &:nth-child(n+3) {
        @media screen and (max-width: $xs-max) {
          margin-top: 1.5rem;
        }  
      }
    }

    a {
      font-size: 1.4rem;
      font-weight: 900;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.2s color;

      @media screen and (min-width: $lg-min) {
        font-size: 1.6rem;
      }

      &:hover,
      &:focus {
        color: $c-main;
      }
    }
  }

  &__subtitle {
    margin: 0 0 2.5rem;
    color: $c-success;
    font-size: 1.8rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
      font-size: 2.4rem;
      text-align: center;
      font-weight: 600;
    }
  }

  &__info {
    @media screen and (min-width: $lg-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.5rem;
    }
  }

  &__info-imgs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2.5rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 0;
    }

    img {
      border-radius: 0.8rem;

      &:nth-child(n+2) {
        margin-left: 2rem;
      }
    }
  }

  &__info-help {
    display: inline-block;
    margin: 0 0 1.5rem;
    font-size: 1.4rem;

    @media screen and (min-width: $lg-min) {
      margin: 0 0.5rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__tel {
    margin: 0 0 1.5rem;
    font-size: 1.8rem;
    font-weight: 700;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 0;
    }

    svg {
      margin-right: 0.5rem;

      @include size(1.6rem, 1.6rem);
    }
  }

  &__desc {
    margin: 0 0 2rem;
    font-size: 1.2rem;
    color: #a0a1a1;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    font-size: 1.2rem;

    li {
      &:nth-child(2) {
        position: relative;
        margin-left: 1rem;
        padding-left: 1rem;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          background: #A9A9A9;
          transform: translate(0, -50%);
        
          @include size(0.1rem, 1.3rem);
        }
      }

      &:last-child {
        color: #a0a1a1;
        
        @media screen and (max-width: $xs-max) {
          width: 100%;
          margin-top: 2.5rem;
        }

        @media screen and (min-width: $sm-min) {
          position: relative;
          margin-left: 1rem;
          padding-left: 1rem;
  
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background: #A9A9A9;
            transform: translate(0, -50%);
          
            @include size(0.1rem, 1.3rem);
          }
        }
      }
    }

    a {
      text-decoration: none;
      transition: 0.2s color;

      &:hover,
      &:focus {
        color: $c-main;
      }
    }
  }
}